import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/b9LkCyT4gG0">
    <SEO title="A Tale of Kings - Jesus in Genesis" />
  </Layout>
)

export default SermonPost
